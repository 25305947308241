import React from 'react'
import imgSobre from "../Assets/Predio.jpg";
function Branco () {
  return (
    <div id='branco'>
        <div className='about-imagebranco'>
            
        </div>
        <div className='about-text'>
          
        </div>
    </div>
  )
}

export default Branco

import React, { useState } from 'react';
import imgSobre from "../Assets/Propriedade.jpeg";
function Propiedade () {
  // Estado para controlar a exibição do PopUp
  const [showPopup, setShowPopup] = useState(false);

  // Função para lidar com o envio do formulário
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Envia o formulário diretamente
    e.target.submit(); // Isso submeterá o formulário ao action definido no form.

    // Exibe o PopUp quando o formulário for enviado
    setShowPopup(true);
  };

  // Função para fechar o PopUp
  const closePopup = () => {
    setShowPopup(false);
  };
  return (
    <div id='atuacao'>
        
        <div className='atuacao-text'>
            <h1>Direito de Propriedade e Vizinhança</h1>
            <p>A defesa dos direitos de propriedade e a mediação de conflitos de vizinhança são fundamentais para garantir a harmonia nas relações sociais. Atuamos em ações possessórias, usucapião, e questões relacionadas a servidões e direitos de passagem. Buscamos soluções que preservem a convivência pacífica ao mesmo tempo em que respeitem os direitos individuais dos nossos clientes.  
              </p>
              
             
              
            
              <div className='atuacao-image'>
          
          <img src={imgSobre} alt='' />
      </div>
        </div>
        <div className='atuacao-contato'>
          
        <div className='containerFormAtuacao'>


  
  <div className='right'>
  <div className='title'><h1>CONTATO</h1></div>
    <div className='formBoxAtuacao'>
     
      <form onSubmit={handleSubmit} action='https://formsubmit.co/suporte@costadearaujoadvocacia.com.br' method='POST'>
        <p>Digite seu nome</p>
        <input type="text" name="Nome" placeholder="Digite seu nome.." required></input>
        <p>Digite seu email</p>
        <input type="email" name="Email" placeholder="Digite seu email.." required></input>
        <p>Digite seu telefone</p>
        <input type="tel" name="Telefone" placeholder="(00) 0 0000 0000" required></input>
        <p>Escreva sua mensagem</p>
        <input type="text" name="Mensagem" placeholder="Digite sua mensagem..." required></input>
        <input type="hidden" name="_captcha" value="false"></input>
        <input type="hidden" name="_next" value="https://costadearaujoadvocacia.com.br"></input>
        <input type="submit" id="submitPropriedade" value="Enviar"></input>
        <a id="MobileZap" href="https://wa.me/559883141358" target="_blank" rel="noreferrer"><input type="button" name=""  value="WhatsApp"></input></a>
      </form>
    </div>
  </div>


</div>
{/* Condicional para exibir o PopUp */}
{showPopup && (
          <div className="popup">
            <p>Mensagem enviada com sucesso! Nossa equipe entrará em contato.</p>
            <button onClick={closePopup}>Ok</button>
          </div>
        )}
        </div>
    </div>
  )
}

export default Propiedade

import React from 'react'
import imgSobre from "../Assets/Predio.jpg";
function Sobre () {
  return (
    <div id='about'>
        <div className='about-image'>
            <img src={imgSobre} alt='' />
        </div>
        <div className='about-text'>
            <h1>QUEM SOMOS</h1>
            <p>O Costa de Araújo é um escritório de advocacia que estabelece um novo padrão em assistência legal. Especializados em direito cível e criminal, direito do consumidor, direito bancário e empresarial, somos dedicados a fornecer serviços jurídicos de alta qualidade.

Nossa missão é superar as expectativas com uma advocacia preventiva, estratégica e humanizada.

Nosso compromisso é entender cada história e trabalhar para que cada cliente sinta que suas necessidades estão sendo realmente atendidas.

Em cada etapa, nossos profissionais experientes buscam a excelência, oferecendo análises detalhadas e abordagens individualizadas para assegurar os melhores resultados possíveis.

Justiça e ética são os pilares de nossa prática e o compromisso com a confiança e integridade permeia todos os nossos serviços. O escritório Costa de Araújo está sempre pronto para lhe oferecer a melhor orientação jurídica, planejamento processual e consultoria especializada.
</p>
            
        </div>
    </div>
  )
}

export default Sobre

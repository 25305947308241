import React from "react";

import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

import { FaTwitter } from "react-icons/fa";


const Footer = () => {
  return (
    <div id="foot">
    <div className="footer-wrapper">
      
      <div className="footer-section-one">
      <div className="footer-section-columns">
          
          <span><p className="contatss">Costa de Araújo Advocacia <br />©Todos os direitos reservados</p> 
          
          <p className="contatss2">Av. Ana Jansen, Edf. Mendes Frota, Sala 105</p> 
          <p className="contatss2">São Luís/MA  - CEP 65076-730</p> 
          <a href="tel:983141358"><p className="contatss2">(98) 9 8314-1358</p></a>
          </span>
          
          
        </div>
        </div>
        <div className="footer-section-center">
          <div className="footer-section-columns">
          
          <div className="formDiv flex"> 
        <div className="headerDiv">
        <a href="/landpage">
          
          </a>
          
        </div> 
        <div className='formBoxLetter'>
        <form action='https://formsubmit.co/suporte@costadearaujoadvocacia.com.br' method='POST'>
        
        <p>Receba nossa newsletter</p>
        <input type="email" name="Email" placeholder="Digite seu email.." required></input>
        
        
        <input type="hidden" name="_captcha" value="false"></input>
        <input type="hidden" name="_next" value="https://costadearaujoadvocacia.com.br/"></input>
        <input type="submit" id="submitNewsletter" value="Cadastrar"></input>
        
      </form>
</div>
      </div>
          
            
           
          </div>
        </div>
        <div className="footer-section-two">
        <div className="footer-section-columns">
          
                   
        
          <div className="footer-icons-two" id="footer-icons">
        <span>
         <a id="footInsta" href="https://www.instagram.com/costadearaujoadvocacia" target="_blank" rel="noreferrer"><FaFacebookF /></a>
         <a id="footInsta" href="https://www.instagram.com/costadearaujoadvocacia" target="_blank" rel="noreferrer"><FaInstagram /></a>
         <a id="footInsta" href="https://www.instagram.com/costadearaujoadvocacia" target="_blank" rel="noreferrer"><FaTwitter /></a>
         
         </span>
         
         {/*<a href="mailto:contato@contato.com.br" target="_blank" rel="noreferrer"><HiOutlineMail /></a>*/}
         </div>
          
        </div>
        
        
      </div>
    
    </div>
    </div>
  );
};

export default Footer;
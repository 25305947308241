import React, { useState, useEffect, useRef, useCallback } from "react";
import fundo1 from "../Assets/videoAdv.gif";
import fundo2 from "../Assets/LOGOTIPO_APROVADO.png";
import { Link } from "react-scroll";
import { FaWhatsapp } from "react-icons/fa";

function Home() {
  const [count, setCount] = useState(1);
  const [text, setText] = useState({ title: "Advocacia preventiva e estratégica <br><span>Atendimento humanizado</span>", details: "Temos a solução para o seu desafio." });
  const totalImages = 2;
  const intervalId = useRef(null);

  const nextImage = useCallback(() => {
    setCount((prevCount) => (prevCount >= totalImages ? 1 : prevCount + 1));
  }, [totalImages]);

  const startAutoSlide = useCallback(() => {
    intervalId.current = setInterval(() => {
      nextImage();
    }, 5000);
  }, [nextImage]);

  useEffect(() => {
    startAutoSlide();
    return () => clearInterval(intervalId.current);
  }, [startAutoSlide]);

 /* useEffect(() => {
    switch (count) {
      case 1:
        setText({ title: "CONHEÇA A <span>VINCC1</span>", details: "Tecnologia e Inovação" });
        break;
      case 2:
        setText({ title: "DESCUBRA NOSSAS <span>SOLUÇÕES</span>", details: "" });
        break;
      default:
        setText({ title: "CONHEÇA A <span>VINCC1</span>", details: "Tecnologia e Inovação" });
    }
  }, [count]);*/


  const manualChange = (index) => {
    clearInterval(intervalId.current);
    setCount(index);
    startAutoSlide();
  };

  return (
    <div id="main">
      <div className="name">
      <h2 dangerouslySetInnerHTML={{ __html: text.title }}></h2>
        
      {text.details && <p className="details">{text.details}</p>}
      <br /><br />
      <div className="ZapBox">
  <a id="ZapHome" href="https://wa.me/559883141358?text=Olá,%20gostaria%20de%20falar%20com%20um%20advogado%20especialista" target="_blank" rel="noreferrer">
    <button type="button">
      <FaWhatsapp className="whatsapp-icon" />
      Fale com especialista
    </button>
  </a>
</div>
      </div>
      <div className="slider">
        <div className="slides">
          {/*<input type="radio" name="radio-btn" id="radio1" checked={count === 1} readOnly />*/}
          
          

          <div className="slide first">
            <img src={fundo1} alt="s" />
          </div>
          
            <div className="slide">
            <Link to="features" smooth={true} duration={1000}>
              {/*<img src={fundo2} alt="Imagem 2" />*/}
            </Link>
            </div>
          
          

          <div className="navigation-auto">
            {/*<div className="auto-btn1"></div>*/}
            
            
          </div>
        </div>
      </div>

      <div className="manual-navigation">
        {/*<label htmlFor="radio1" className="manual-btn" onClick={() => manualChange(1)}></label>*/}
        
        
      </div>
    </div>
  );
}

export default Home;

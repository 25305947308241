import React, { useState } from 'react';
import imgSobre from "../Assets/advogado-contratual.webp";

function Contratos() {
  // Estado para controlar a exibição do PopUp
  const [showPopup, setShowPopup] = useState(false);

  // Função para lidar com o envio do formulário
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Envia o formulário diretamente
    e.target.submit(); // Isso submeterá o formulário ao action definido no form.

    // Exibe o PopUp quando o formulário for enviado
    setShowPopup(true);
  };

  // Função para fechar o PopUp
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div id='atuacao'>
      <div className='atuacao-text'>
        <h1>Contratos</h1>
        <p>No nosso escritório, entendemos que os contratos são a base de muitas relações comerciais e pessoais. Oferecemos consultoria e assessoria na elaboração, revisão e negociação de contratos, garantindo que todos os termos sejam claros e que os interesses de nossos clientes estejam devidamente protegidos. Nosso foco é evitar litígios futuros e assegurar que os acordos sejam cumpridos de maneira justa.</p>
        <div className='atuacao-image'>
          <img src={imgSobre} alt='' />
        </div>
      </div>
      <div className='atuacao-contato'>
        <div className='containerFormAtuacao'>
          <div className='right'>
            <div className='title'><h1>CONTATO</h1></div>
            <div className='formBoxAtuacao'>
              <form onSubmit={handleSubmit} action='https://formsubmit.co/suporte@costadearaujoadvocacia.com.br' method='POST'>
                <p>Digite seu nome</p>
                <input type="text" name="Nome" placeholder="Digite seu nome.." required />
                <p>Digite seu email</p>
                <input type="email" name="Email" placeholder="Digite seu email.." required />
                <p>Digite seu telefone</p>
                <input type="tel" name="Telefone" placeholder="(00) 0 0000 0000" required />
                <p>Escreva sua mensagem</p>
                <input type="text" name="Mensagem" placeholder="Digite sua mensagem..." required />
                <input type="hidden" name="_captcha" value="false" />
                <input type="hidden" name="_next" value="https://costadearaujoadvocacia.com.br/#" />
                <input type="submit" id="submitContrato" value="Enviar" />
                <a id="MobileZap" href="https://wa.me/559883141358" target="_blank" rel="noreferrer">
                  <input type="button" value="WhatsApp" />
                </a>
              </form>
            </div>
          </div>
        </div>

        {/* Condicional para exibir o PopUp */}
        {showPopup && (
          <div className="popup">
            <p>Mensagem enviada com sucesso! Nossa equipe entrará em contato.</p>
            <button onClick={closePopup}>Ok</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Contratos;

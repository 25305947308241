/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logo from "../Assets/LOGOTIPO_APROVADO_MARCA_D_AGUA_1.png";
import logo2 from "../Assets/LOGOTIPO_APROVADO_MARCA_D_AGUA_4.png";
import { Link } from "react-scroll";
import { FaWhatsapp } from "react-icons/fa";


function Navbar ({ setPage }) {
  const [nav, setnav] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 50) {
        setnav(true);
    }
    else{
        setnav(false);
    }
  }
  window.addEventListener('scroll',changeBackground);

  const closeMenu = () => {
    document.getElementById('menu-btn').checked = false;
  };
  
  
  return (
    <nav className={nav ? "nav active" :"nav"}>
        <Link to='main' className='logo' smooth={true} duration={1000}>
        <img src={nav ? logo : logo2} alt="Logo" />
        </Link>
        <input className='menu-btn' type="checkbox" id='menu-btn'/>
        <label className="menu-icon" for="menu-btn">
            <span className="nav-icon"></span>
        </label>     
        <ul className="menu header">
            <li><Link to='main' onClick={() => {setPage("home"); closeMenu();}}  smooth={true} duration={1000}  >Home</Link></li>
            <li><Link to='about' onClick={() => {setPage("home"); closeMenu();}}  smooth={true} duration={1000} >Quem Somos</Link></li>
            
            <li className="dropdown">
            <Link to='features' smooth={true} duration={1000} className="dropdown-toggle">Área de Atuação</Link>
            <ul className="dropdown-menu">
                <li><a id="MenuContratos" href="#" onClick={() => {setPage("Contratos"); closeMenu();}}>Contratos</a></li>
                <li><a id="MenuConsumidor" href="#" onClick={() => {setPage("Consumidor"); closeMenu();}}>Direito do Consumidor</a></li>
                <li><a id="MenuFamilia" href="#" onClick={() => {setPage("Familia"); closeMenu();}}>Direito de Família</a></li>
                <li><a id="MenuPropriedade" href="#" onClick={() => {setPage("Propiedade"); closeMenu();}}>Direito de Propriedade </a></li>
                <li><a id="MenuBancario" href="#" onClick={() => {setPage("Bancario"); closeMenu();}}>Direito Bancário</a></li>
                <li><a id="MenuEmpresarial" href="#" onClick={() => {setPage("Empresarial"); closeMenu();}}>Direito Empresarial</a></li>
                <li><a id="MenuCriminal" href="#" onClick={() => {setPage("Criminal"); closeMenu();}}>Direito Criminal</a></li>
                <li><a id="MenuExtrajudicial" href="#" onClick={() => {setPage("Extrajudicial"); closeMenu();}}>Resolução Extrajudicial</a></li>
                <li><a id="MenuPrevidenciario" href="#" onClick={() => {setPage("Previdenciario"); closeMenu();}}>Direito Previdenciário</a></li>
            </ul>
        </li>


            
            <li><Link to='presentaion' onClick={() => {setPage("home"); closeMenu();}}  smooth={true} duration={1000} >Contato</Link></li>
                       
        </ul>
        <div className="footer-icons" id="footer-icons ConvertZap">
        
         {/*<a href="https://www.instagram.com/costadearaujoadvocacia/" target="_blank" rel="noreferrer"><FaInstagram /></a>*/}
         <a id="NavZap"  href="https://wa.me/559883141358" target="_blank" rel="noreferrer"><FaWhatsapp data-gtm="whatsapp-click" /></a>
         </div>
    </nav>
  );
};

export default Navbar;
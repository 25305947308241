
import fundo1 from "../Assets/videoAdv.gif";
import React from "react";

function BrancoTopo () {

  
  return (
    <div id='brancoTopo'>
      <div className='about-imagebrancotopo'>
        
      
      <div className="slider">
        <div className="slides">         
          

          <div className="slide first">
            <img src={fundo1} alt="s" />
          </div>
          
              
          
        </div>
      </div>
    
    </div>
    </div>
  )
}

export default BrancoTopo

import React from 'react'
import imgSobre from "../Assets/Predio.jpg";
function Brancobranco () {
  return (
    <div id='brancobranco'>
        <div className='about-imagebrancobranco'>
            
        </div>
        <div className='about-text'>
          
        </div>
    </div>
  )
}

export default Brancobranco

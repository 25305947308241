import React from 'react'
import imgSobre from "../Assets/criminal.jpeg";
function GeralObrigado () {
  return (
    <div id='atuacao'>
        
        <div className='atuacao-text'>
            <h1>Formulário enviado com sucesso</h1>
            <p>Nossa equipe entrará em contato com você 
              </p>
              
              
              
            
              <div className='atuacao-image'>
          
          <img src={imgSobre} alt='' />
      </div>
        </div>
        
    </div>
  )
}

export default GeralObrigado

import React, { useState } from 'react';


function Contact () {
  // Estado para controlar a exibição do PopUp
  const [showPopup, setShowPopup] = useState(false);

  // Função para lidar com o envio do formulário
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Envia o formulário diretamente
    e.target.submit(); // Isso submeterá o formulário ao action definido no form.

    // Exibe o PopUp quando o formulário for enviado
    setShowPopup(true);
  };

  // Função para fechar o PopUp
  const closePopup = () => {
    setShowPopup(false);
  };
  
  return (
    
    <div id="presentaion">
        
<div className='containerForm'>

<div className='left'>
<div className='contato-text'><h1>PODEMOS TE AJUDAR</h1>
<p>Seja qual for o seu desafio, estamos aqui para te auxiliar. Sinta-se à vontade para entrar em contato conosco. Estamos aqui para atender e entender você! Contribua para que nossa equipe ofereça um atendimento cada vez melhor e serviços que atendam às suas necessidades. Preencha o formulário com seu feedback, crítica, pedido ou sugestão.
</p>
  </div>
          {/* Google Maps Iframe */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3986.0071301142266!2d-44.30737952503113!3d-2.504528397474044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f68dcd2074bee7%3A0xf8409d58306cba18!2sEdif%C3%ADcio%20Mendes%20Frota%20(Centro%20empresarial)!5e0!3m2!1spt-BR!2sbr!4v1728017914569!5m2!1spt-BR!2sbr"
            width="600"
            height="400"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
  
  <div className='right'>
  <div className='title'><h1>CONTATO</h1></div>
    <div className='formBox'>
     
      <form onSubmit={handleSubmit} action='https://formsubmit.co/suporte@costadearaujoadvocacia.com.br' method='POST'>
        <p>Digite seu nome</p>
        <input type="text" name="Nome" placeholder="Digite seu nome.." required></input>
        <p>Digite seu email</p>
        <input type="email" name="Email" placeholder="Digite seu email.." required></input>
        <p>Digite seu telefone</p>
        <input type="tel" name="Telefone" placeholder="(00)0 0000 0000" required></input>
        <p>Escreva sua mensagem</p>
        <input type="text" name="Mensagem" placeholder="Digite sua mensagem..." required></input>
        <input type="hidden" name="_captcha" value="false"></input>
        <input type="hidden" name="_next" value="https://costadearaujoadvocacia.com.br/#"></input>
        <input type="submit" id="submitContato" value="Enviar"></input>
        <a id="MobileZap" href="https://wa.me/559883141358" target="_blank" rel="noreferrer"><input type="button" name=""  value="WhatsApp"></input></a>
      </form>
    </div>
  </div>


</div>
 {/* Condicional para exibir o PopUp */}
 {showPopup && (
          <div className="popup">
            <p>Mensagem enviada com sucesso! Nossa equipe entrará em contato.</p>
            <button onClick={closePopup}>Ok</button>
          </div>
        )}
        

    </div>
  )
}
export default Contact